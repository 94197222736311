import * as React from "react";
import PersonalLayout from "../../../../components/pages/personal/PersonalLayout";
import PersonalBody from "../../../../components/pages/personal/PersonalBody";
import OptionCard from "../../../../components/cards/optionCard";
import {Button, message, Modal, Space} from "antd";
import PersonalAddCard from "../../../../components/pages/personal/PersonalAddCard";
import Seo from "../../../../components/seo";
import {Link, navigate} from "gatsby";
import auth from "../../../../utils/auth";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import jsonRequest from "../../../../utils/request/jsonRequest";
import * as Moment from "moment/moment";

const PersonalExperience = () => {
  auth.authorize()
  const {account} = useSelector((s: any) => s)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const load = () => {
    if (loading) {
      return;
    }
    setLoading(true)
    jsonRequest("/account/experience").then(rsp => {
      if (rsp.success) {
        setData(rsp.data.data.map(item => ({
          ...item,
          startTime: Moment(item.startTime),
          endTime: item.endTime ? Moment(item.endTime) : null,
        })))
      } else {
        message.error(rsp.message)
      }
    }).catch(console.log).finally(() => {
      setLoading(false)
    })
  }

  const handleDestroy = (id: any) => {
    Modal.confirm({
      title: '确定删除?',
      okText: '删除',
      cancelText: '取消',
      onOk: () => {
        return jsonRequest("/account/experience", {
          method: 'DELETE',
          data: {id}
        }).then(rsp => {
          if (rsp.success) {
            message.success('删除成功')
            load()
          } else {
            message.error(rsp.message)
          }
        }).catch(console.log)
      }
    })
  }

  useEffect(() => {
    if (account) {
      load()
    }
  }, [account])

  return (<>
    <PersonalLayout selected='resume'>
      <PersonalBody title='工作经历' backLink='/personal/resume'>
        <Space direction='vertical' size={16} style={{width: '100%'}}>
          {data && data.map(item => (
            <OptionCard
              key={item.id}
              title={item.company}
              titleAppend={item.job}
              desc={`${item.startTime.format("YYYY-MM-DD")} - ${item.endTime?.format("YYYY-MM-DD") ?? '至今'}`}
              options={(
                <Space size={8}>
                  <Link to={`/personal/resume/experience/edit?id=${item.id}`}><Button>编辑</Button></Link>
                  <Button type='primary' danger onClick={() => handleDestroy(item.id)}>删除</Button>
                </Space>
              )}
            />
          ))}
          <PersonalAddCard
            text='增加经历'
            onClick={() => navigate('/personal/resume/experience/add')}
          />
        </Space>
      </PersonalBody>
    </PersonalLayout>
  </>)
}

export default PersonalExperience;

export const Head = () => <Seo />
